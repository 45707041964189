<template>
  <mf-tooltip
    :top="alignTop"
    :bottom="alignBottom"
    :left="alignLeft"
    :right="alignRight"
    max-width="192px"
    :disabled="disabled"
    :variant="darkMode ? 'secondary' : 'primary'"
  >
    <template v-slot:activator="{ on }">
      <div :style="fitContentClass" v-on="on">
        <slot></slot>
      </div>
    </template>
    <span :style="darkMode ? 'color: #646464' : ''">{{ message }}</span>
  </mf-tooltip>
</template>

<script>
export default {
  name: 'Tooltip',
  props: {
    disabled: {
      type: Boolean,
      default: false
    },
    message: {
      type: String,
      default: ''
    },
    align: {
      type: String,
      default: 'top'
    },
    fitContent: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    alignTop() {
      return this.align === 'top'
    },
    alignBottom() {
      return this.align === 'bottom'
    },
    alignRight() {
      return this.align === 'right'
    },
    alignLeft() {
      return this.align === 'left'
    },
    fitContentClass() {
      return this.fitContent ? { width: 'fit-content' } : null
    },
    darkMode() {
      return localStorage.getItem('dark_theme') === 'true'
    }
  }
}
</script>
